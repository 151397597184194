import React, { useState, useEffect } from "react";
import "./test.css";

// const SkeletonLoader = () => (
//   <div style={{ padding: "1rem" }}>
//     <div
//       className="pulse-loader"
//       style={{
//         height: "24px",
//         width: "70%",
//         marginBottom: "16px",
//       }}
//     ></div>
//     <div
//       className="pulse-loader"
//       style={{
//         height: "18px",
//         width: "90%",
//         marginBottom: "12px",
//       }}
//     ></div>
//     <div
//       className="pulse-loader"
//       style={{
//         height: "18px",
//         width: "60%",
//       }}
//     ></div>
//   </div>
// );

const SkeletonLoader = () => (
    <div style={{ padding: "1rem" }}>
      <div
        className="skeleton-shimmer"
        style={{
          height: "20px",
          width: "70%",
          marginBottom: "10px",
        }}
      ></div>
      <div
        className="skeleton-shimmer"
        style={{
          height: "15px",
          width: "90%",
          marginBottom: "8px",
        }}
      ></div>
      <div
        className="skeleton-shimmer"
        style={{
          height: "15px",
          width: "60%",
        }}
      ></div>
    </div>
  );
  

// Actual Content Component
const ActualContent = () => (
  <div style={{ padding: "1rem" }}>
    <h2>Loaded Content</h2>
    <p>This is the actual content that loads after the skeleton disappears.</p>
  </div>
);

// TestComponent
const TestComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 1-3 seconds
    const delay = Math.random() * 2000 + 1000; // Random between 1000ms and 3000ms
    const timer = setTimeout(() => setLoading(false), delay);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return <div style={{ background: "black" }}>{loading ? <SkeletonLoader /> : <ActualContent />}</div>;
};

export default TestComponent;