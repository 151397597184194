import React from 'react';
import { Link } from 'react-router-dom';
import './page.css';

function Footer() {
    return (
        <div className='footer-container align-items-center justify-content-center'>
            <svg>
                <filter id="grain">
                    <feTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="3" />
                    <feColorMatrix in="colorNoise" type="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                    <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
                    <feBlend mode="multiply" in="SourceGraphic" in2="monoNoise" />                            
                </filter>
            </svg>
            <footer>
                <p>© 2025 Vidtex. All Rights Reserved.</p>
                <div className='backdrop'></div>
            </footer>
        </div>
    );
}

function Navbar() {
    return (
        <nav className='navbar-container'>
            <div>
                <Link to="/" className='navbar-brand'>
                    Vidtex.ai
                </Link>
            </div>
            <div>
                {/* Adjust these links as needed */}
                <Link to="/" className='navbar-link'>
                    Product
                </Link>
                <Link to="/about" className='navbar-link'>
                    About
                </Link>
            </div>
        </nav>
    );
}

function Page({ PageComponent }) {
    return (
        <div className="App">
            <Navbar />
            {/* 
                Add top padding so the content 
                doesn’t hide behind the fixed navbar.
            */}
            <div
                className="page"
                style={{
                    minHeight: '100vh'   // Optional: fill viewport if desired
                }}
            >
                <PageComponent />
            </div>
            <Footer />
        </div>
    );
}

export { Page };
