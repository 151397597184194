// src/pages/About.js
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './about.css';

function About() {
    const [pathD, setPathD] = useState('');
    const generateHillsPath = (width, height, segments, amplitude) => {
        let path = `M0,${height}`;
        const segmentWidth = width / segments;

        for (let i = 0; i <= segments; i++) {
            if (i % 5 == 0) {
                amplitude = Math.random() * 30; 
            }
            const x = i * segmentWidth;
            const y = height - amplitude / 2 + Math.random() * amplitude;
            path += ` L${x},${y-30}`;
        }

        path += ` L${width},${height} L0,${height} Z`;
        return path;
    };

    useEffect(() => {
        const width = 1440;
        const height = 200;
        const segments = 100;
        const amplitude = 0;
        setPathD(generateHillsPath(width, height, segments, amplitude));
    }, []);
    return (
        <div className="about">
            {/* ABOUT CONTENT */}
            <section className="about-container w-100 py-5 align-items-center justify-content-center">
                <div className="container about-content w-100">
                    <h1 className="title display-4 fw-bold mt-5 text-left">
                        About
                    </h1>
                    <p className="description lead mt-3 mb-1 text-left">
                        Vidtex.ai is founded by <a href='https://keyneoei.dev'>Keyne Oei</a>.
                        <br/><br/>
                        Our mission is to harness AI to make the art of filmmaking more intuitive, efficient, 
                        and impactful. We believe that the future of video editing is in the hands of AI,
                        and we are excited to be at the forefront of this revolution.
                        <br/><br/>
                        We are currently in the prototype development phase of VidTex, our AI tool for filmmakers. 
                        The focus is on building and refining the core features, including video temporal action localization, video understanding, and video-text multimodal capabilities. 
                    </p>
                </div>

                {/* Background*/}
                <div className='grain-container'>
                    <div className='grain-effect-2'>
                        <svg>
                            <filter id="grainFilter2">
                            <feTurbulence type="fractalNoise" baseFrequency="0.3" numOctaves="3" />
                            <feColorMatrix type="saturate" values="2" />
                            </filter>
                        </svg>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 3,
                        overflow: 'hidden',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 200"
                        preserveAspectRatio="none"
                        className='hills-svg'
                        fill="url(#gradient)"
                    >
                        <filter id="grain">
                            <feTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="3" />
                            <feColorMatrix in="colorNoise" type="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                            <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
                            <feBlend mode="multiply" in="SourceGraphic" in2="monoNoise" />                            
                        </filter>
                        <filter id="innershadow2">
                            <feComposite in="SourceGraphic" in2="SourceGraphic" operator="over" />
                            <feMorphology operator="dilate" radius="1" in="SourceGraphic" result="spread" />
                            {/* <feGaussianBlur stdDeviation="10" result="shadow" /> */}
                            <feOffset in="shadow" dx="2" dy="0" result="movedShadow" />
                            <feFlood flood-color="white" result="color" />
                            <feComposite in="color" in2="movedShadow" operator="in" result="coloredShadow" />
                            <feComposite in="SourceGraphic" in2="coloredShadow" operator="over" />
                        </filter>
                        <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0%" stopColor="#482188" />
                            <stop offset="100%" stopColor="#0B0D1A" />
                        </linearGradient>
                        <path
                            style={{ zIndex: 1}}
                            filter="url(#innershadow2) url(#grain) "
                            fillOpacity="1"
                            d={pathD}
                        />
                    </svg>
                </div>
            </section>
        </div>
    );
}

export {About};
