import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import ParticlesComponent from '../utils/particles';
import { Tilt } from 'react-tilt';

function Section1() {
    useEffect(() => {
        const handleScroll = () => {
            const parallaxImage = document.querySelector('.parallax-image');
            const content = document.querySelector('.title');
            const backgroundLayer = document.querySelector('.section1');
            
            // Define scrollPosition here
            const scrollPosition = window.scrollY;
        
            if (parallaxImage) {
                parallaxImage.style.transform = `translateY(${scrollPosition * 0.3}px)`;
            }

            if (content) {
                content.style.transform = `translateY(${scrollPosition * 1.05}px)`;
            }
        
            if (backgroundLayer) {
                backgroundLayer.style.transform = `translateY(${scrollPosition * 0.1}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {/* SECTION 1 */}
            <section className="section1 parallax-hero d-flex align-items-center justify-content-center">
            <ParticlesComponent />
            <div className="overlay"></div>

                {/* Content */}
                <div className="container content">
                    <h1 className="title display-4 fw-bold mt-5">
                        Smart Video Insights,<br /> Smarter Filmmaking
                    </h1>
                    <p className="description lead mt-3 mb-1">
                        VidTex is an AI tool transforming filmmaking with 
                        video action localization, understanding, and text integration, 
                        streamlining analysis and enhancing storytelling for 
                        smarter, faster creativity.
                    </p>
                    {/* Parallax Image */}
                    <div className="image-container">
                        <img
                            src="/landing/vidtex_landing.png"
                            alt="VidTex Landing"
                            className="parallax-image"
                        />
                    </div>
                </div>

                {/* Background*/}
                <div className='background-slash'>
                    <svg viewBox="0 0 800 600" width="100%" height="100%">
                        <defs>
                            <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="30%" style={{ stopColor: '#0B0D1A', stopOpacity: 1 }} />
                                <stop offset="100%" style={{ stopColor: '#482188', stopOpacity: 1 }} />
                            </linearGradient>
                        </defs>
                    <path 
                    class="background-slash-path" 
                    d="M325.43-5.43s-79.22,186.18,36.97,280.6c116.18,94.42,248.22,204.83,248.22,204.83,0,0-503.58-144.11-567.54-223.16"
                    />
                    </svg>
                </div>
                <div className='grain-container'>
                    <div className='grain-effect'>
                        <svg>
                            <filter id="grainFilter">
                            <feTurbulence type="fractalNoise" baseFrequency="0.3" numOctaves="3" />
                            {/* <feTurbulence type="turbulence" baseFrequency="0.2" numOctaves="3" /> */}
                            <feColorMatrix type="saturate" values="2" />
                            </filter>
                        </svg>
                    </div>
                </div>
                
            </section>
        </div>
    );
}

function Section2() {
    const tiltOptions = {
        reverse: false,
        startX: 30,
        max: 30,
        perspective: 2000,
        scale: 1.05,
        speed: 500,
        transition: false,
        axis: null,
        reset: false,
        glare: true,
        easing: "cubic-bezier(.03,.98,.52,.99)",
    }
    const [currentImage, setCurrentImage] = useState('/landing/fe1.png');
    const [pathD, setPathD] = useState('');

    const handleHover = (image) => {
        setCurrentImage(image);
    };
    
    const generateHillsPath = (width, height, segments, amplitude) => {
        let path = `M0,${height}`;
        const segmentWidth = width / segments;

        for (let i = 0; i <= segments; i++) {
            if (i % 5 == 0) {
                amplitude = Math.random() * 30; 
            }
            const x = i * segmentWidth;
            const y = height - amplitude / 2 + Math.random() * amplitude;
            path += ` L${x},${y-30}`;
        }

        path += ` L${width},${height} L0,${height} Z`;
        return path;
    };

    useEffect(() => {
        const width = 1440;
        const height = 200;
        const segments = 100;
        const amplitude = 0;
        setPathD(generateHillsPath(width, height, segments, amplitude));
    }, []);

    function FeatureDiv( icon, title, description, image ) {
        return (
            <div
                className="row feature highlight-on-hover"
                onMouseEnter={() => handleHover(image)}
            >
                <div className="col-md-2">
                    <div className="me-3">
                        <span className="material-icons" style={{ fontSize: '3rem' }}>
                            {icon}
                        </span>
                    </div>
                </div>
                <div className="col-md-10">
                    <div>
                        <h5 className="fw-bold">{title}</h5>
                        <p className='text-sm'>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {/* SECTION 2 (Product Features) */}
            <section
                id="product"
                className="section2 py-5"
            >

                <div style={{ position: 'relative', height: '', overflow: 'hidden' }}>
                    
                </div>

                {/* CONTENT */}
                <div className="container">
                    <h2 className="fw-bold mb-5" style={{ fontSize: '2rem' }}>
                        Product Features
                    </h2>
                    <div className="row text-left mt-5">

                        {/* 3 FE */}
                        <div className="col-md-6 feature-container">
                            {/* fe1 */}
                            {FeatureDiv(
                                'description',
                                'Action Segmentation & Highlights',
                                'Automatically identify and segment key moments in footage (e.g., action scenes, dialogue-heavy sequences, or transitions).',
                                '/landing/fe1.png'
                            )}

                            {/* fe2 */}
                            {FeatureDiv(
                                'movie',
                                'Script-to-Scene Mapping',
                                'Match video and audio sequences with screenplay text for easy script-to-footage alignment. Allow filmmakers to search for specific moments by describing them in text.',
                                '/landing/fe2.png'
                            )}

                            {/* fe3 */}
                            {FeatureDiv(
                                'insights',
                                'AI-Powered Editing Insights',
                                'Provide intelligent suggestions for transitions, pacing, and continuity to optimize video flow and enhance creative impact.',
                                '/landing/fe3.png'
                            )}
                        </div>

                        {/* Dynamically changing feature image */}
                        <div className="col-md-6">
                            <div className='blob'>
                            </div>
                            <Tilt options={tiltOptions} style={{ height: '100%', width: '100%' }}>
                                <div>
                                    <img
                                        src={currentImage}
                                        alt="Feature Illustration"
                                        className="img-fluid"
                                    />
                                </div>
                            </Tilt>
                        </div>
                    </div>
                </div>

                {/* BACKGROUND */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1,
                        overflow: 'hidden',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 200"
                        preserveAspectRatio="none"
                        className='hills-svg'
                        fill="url(#gradient)"
                    >
                        <filter id="grain">
                            <feTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="3" />
                            <feColorMatrix in="colorNoise" type="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                            <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
                            <feBlend mode="multiply" in="SourceGraphic" in2="monoNoise" />                            
                        </filter>
                        <filter id="innershadow">
                            <feComposite in="SourceGraphic" in2="SourceGraphic" operator="over" />
                            <feMorphology operator="dilate" radius="1" in="SourceGraphic" result="spread" />
                            {/* <feGaussianBlur stdDeviation="10" result="shadow" /> */}
                            <feOffset in="shadow" dx="2" dy="0" result="movedShadow" />
                            <feFlood flood-color="white" result="color" />
                            <feComposite in="color" in2="movedShadow" operator="in" result="coloredShadow" />
                            <feComposite in="SourceGraphic" in2="coloredShadow" operator="over" />
                        </filter>
                        <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0%" stopColor="#482188" />
                            <stop offset="100%" stopColor="#0B0D1A" />
                        </linearGradient>
                        <path
                            style={{ zIndex: 1 }}
                            filter="url(#innershadow) url(#grain) "
                            fillOpacity="1"
                            d={pathD}
                        />
                    </svg>
                </div>

            </section>
        </div>
    );
}

function Section3() {
    return (
        <div>
            {/* SECTION 3 (Coming Soon) */}
            <section
                id="coming-soon"
                className="section3 align-items-center justify-content-center"
            >
                <div className="container content">
                    <h1 className="title display-4 fw-bold mt-5">
                        Smart Video Insights,<br /> Smarter Filmmaking
                    </h1>
                    <h2 className="coming-soon-text fw-bold mb-4" style={{ fontSize: '2rem' }}>
                        Coming Soon
                    </h2>
                    <p className="" style={{ maxWidth: '600px', margin: '0 auto' }}>
                        All above features are in development and will be available soon.
                        <br/>Images above from La La Land (2016). 
                        Copyright © Lionsgate, Summit Entertainment, Marc Platt Productions, Gilbert Films. 
                    </p>
                </div>
            </section>
        </div>
    )
}

function Home() {
    return (
        <div
            className="text-white"
            style={{
                fontFamily: 'Montserrat, sans-serif',
                backgroundColor: '#101010',
            }}
        >
            {Section1()}
            {Section2()}
            {Section3()}
            
        </div>
    );
}

export { Home };
