import React, { useCallback, useEffect, useState } from "react";
import Particles, {initParticlesEngine} from "@tsparticles/react";
import { loadFull } from "tsparticles";
import particlesOptions from "../../particles.json";

function ParticlesComponent() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    return (
        <div>
            {init && <Particles options={particlesOptions}/>}
        </div>
    );
}

export default ParticlesComponent;
