import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './utils/global.css';
import {Page} from './page/page';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { Home } from './page/home/home';
import { About } from './page/about/about';
import TestComponent from './test';

function TestPage() {
    return (
        <div>
            <h1>Test Page</h1>
        </div>
    );
}

function HelloWorld() {
    return (
        <div>
            <h1>Hello World</h1>
        </div>
    );
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Page PageComponent={Home} />} />
                <Route path='/about' element={<Page PageComponent={About} />} />
                <Route path='/test' element={<Page PageComponent={TestComponent} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
